import React, { useState, useEffect, useRef } from 'react'
import { formatDistanceToNow } from 'date-fns'
import "./index.scss"
import classNames from 'classnames';
import { connect } from 'react-redux';
import { openInNewTab } from '../../utilities/customRoutes';
import { isEqual } from 'lodash';
import Skeleton from '../../../helpers/Skeleton';
import { convertTime } from '../../utilities/customFunctions';
import { DEAL_BRIEFING, NEW_ACCOUNT, NEW_NETWORK, NEW_USER } from './routes';
import { DEAL_AUDIENCES_LINK, DEAL_CREATIVES_LINK } from '../../audiences/contextual/routes';
import { createPortal } from 'react-dom';

const routeMap = {
    "new_user": NEW_USER,
    "new_account": NEW_ACCOUNT,
    "new_network": NEW_NETWORK,
    "deal_briefing": DEAL_BRIEFING,
    "deal": DEAL_AUDIENCES_LINK, 
    "daily_test": DEAL_CREATIVES_LINK
}
export const NotificationsPopup = (props) => {
    const { notifications } = props
    const [isOpen, setIsOpen] = useState(false)
    const [notificationsData, setNotificationData] = useState(notifications?.data || [])
    const [filterUnread, setFilterUnread] = useState(false)
    const [unreadTotal, setUnreadTotal] = useState(0)
    const listRef = useRef(null);
    const isMobile = window.innerWidth < 576

    useEffect(() => {
        props.getNotifications(props.user.id)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(isOpen) {
            props.getNotifications(props.user.id)
        } 
        const handleClickOutside = (event) => {
          const popDiv = document.querySelector('#bb-popup-notification .pop');
          const notifNoxDiv = document.querySelector('#notification-box-container');
    
          // Check if the click was outside both divs
          if (isOpen && popDiv && notifNoxDiv && !popDiv.contains(event.target) && !notifNoxDiv.contains(event.target)) {
            setIsOpen(false)
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line
    }, [isOpen]);

    useEffect(() => {
        if(!isEqual(notifications?.data, notificationsData)) {
            setNotificationData(notifications?.data || [])
            setUnreadTotal(notifications?.data?.filter(item => !item.is_read).length || 0)
        }
        // eslint-disable-next-line
    }, [notifications?.data])
    const toggleNotification = (tempOpen = !isOpen) => {
        setIsOpen(tempOpen)
    }
    const setReadStatus = (isRead = true, notif = null) => {
        let newArr = []
        if (notif) {
            newArr = notifications?.data.map(item => item.id === notif.id ? { ...item, is_read: isRead } : item)
            setUnreadTotal(isRead ? unreadTotal - 1 :  unreadTotal + 1)
            setNotificationData(newArr)
            props.markNotificationRead(notif.notification_id, newArr, isRead)
        }

    }
    const setReadAllStatus = () => {
        let newArr = []
        newArr = notifications?.data.map(item => ({ ...item, is_read: true }))
        setUnreadTotal(0)
        setNotificationData(newArr)
        props.markNotificationRead(props.user.id, newArr, true, true)

    }
    const handleNotif = notif => {
        let [route, id] = notif.route.split(",")
        let link = "/" + routeMap[route](id)
        if(link !== props.location.pathname) openInNewTab(link)
        else setIsOpen(false)
        if(!notif.is_read) setReadStatus(true, notif)
    }
    const renderLoading = () => {
        return (Array.from({ length: 4 }).map((_, idx) => (<div key={idx} className={classNames("notif-box d-flex py-2 px-2 text-left mx-3 mb-2", idx === 1 ? "unread-msg" : "")}>
            <div className='w-100'>
                <div className="d-flex justify-content-between">
                    <div className="notif-container-text align-self-center">
                        <Skeleton width="200px" height="10px" borderRadius="10px" />
                    </div>
                </div>
                <div className='d-flex justify-content-between chat-time'>
                    <span>
                        <Skeleton width="100px" height="10px" borderRadius="10px" />
                    </span>
                    <span>
                        <Skeleton width="50px" height="10px" borderRadius="10px" />
                    </span>
                </div>
            </div>
        </div>
        )));
    }
    const renderList = () => {
        let data = notificationsData
        if (filterUnread) data = data.filter(item => !item.is_read)
        return (
            <div className="notif-container w-100 d-flex flex-column justify-content-between">
                <div className="header pt-3 px-3 pb-0 text-black">
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className="font-weight-bold mb-0 bb-font_16px">Notifications</h4>
                        <span className='cursor-pointer' onClick={() => toggleNotification(false)}><i className='fas fa-times'></i></span>
                    </div>
                    <div className='notif-subheader my-2 d-flex justify-content-between bb-font_14px'>
                        <div className='filter'>
                            <span onClick={() => setFilterUnread(false)} className={classNames('cursor-pointer', filterUnread ? "" : "selected")}>All</span>
                            <span onClick={() => setFilterUnread(true)} className={classNames('cursor-pointer', filterUnread ? "selected" : "")}>Unread</span>
                        </div>
                        {unreadTotal > 0 ? <span className='cursor-pointer mr-2 bb-color_blue-500' onClick={() => setReadAllStatus()}>Mark all as read</span> : null}
                    </div>
                </div>
                <div className="notification-wrapper text-center h-100">
                    {notifications?.loading ? renderLoading() :
                        <div>
                            {data?.length > 0 ? data?.map((notif, idx) =>{
                                return (<div key={idx} className={classNames("notif-box d-flex py-3 px-2 text-left mx-3", notif.is_read ? "read-msg" : "unread-msg text-black", notif.route ? "cursor-pointer" : "")} onClick={() => notif.route ? handleNotif(notif) : null}>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <div className='col-11 p-0'>
                                            <div className="notif-msg d-flex justify-content-between bb-font_12px">
                                                <div className="notif-container-text align-self-center">
                                                    {notif.message}
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between chat-time bb-font_10px'>
                                                {notif.created_on ? formatDistanceToNow(convertTime(notif.created_on), { addSuffix: true }) : ""}
                                                {/* <span>
                                                    {format((date), 'EEEE, MM/dd')}  -  {notif?.roleId ? <span className=''>Role</span> : <span>Private</span>}
                                                </span>
                                                <span>{date.getHours() +":"+ date.getMinutes()}</span> */}
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end align-items-center col-1 pr-0'>
                                            {notif.is_read ?
                                                <span className='cursor-pointer chat-button d-flex align-items-center bb-font_12px p-2' title='Mark as unread' onClick={(e) => { e.stopPropagation(); setReadStatus(!notif.is_read, notif) }}><i className='far fa-check-circle bb-font_16px bb-color_quaternary-50'></i></span> :
                                                <span className='cursor-pointer chat-button d-flex align-items-center bb-font_12px p-2' title='Mark as read' onClick={(e) => { e.stopPropagation(); setReadStatus(!notif.is_read, notif) }}><i className='fas fa-check-circle bb-font_16px bb-color_blue-500'></i></span>}
                                        </div>
                                    </div>
                                </div>)}
                            ) : null}
                            <div className='d-flex flex-column justify-content-center align-items-center p-3 my-3 bb-font_12px bb-color_bg-color'>
                                <i className='fas fa-smile-beam bb-font_24px bb-color_gray_imp mb-2'></i>
                                Woohoo! You’re all caught up on notifications from the past 30 days
                            </div>
                        </div>
                    }
                    
                </div>
            </div>
        )
    }
    return (
        <div id='bb-popup-notification' ref={listRef} >
            <div className="pop bb-wmc mb-2 mt-1 d-flex justify-content-end cursor-pointer" onClick={() => toggleNotification()}>
                <div className="pop-icon text-center d-flex">
                    <i className="fa fa-bell m-auto bb-font_20px text-white"></i>
                </div>
                {unreadTotal > 0 && <i className="fas fa-circle notif-badge bb-font_10px"></i>}
            </div>
            {createPortal(
                <>
                    <div className={classNames("notification-overlay", isOpen && isMobile ? "d-block" : "d-none")}></div>
                    <div id='notification-box-container' className={classNames("notification-box-container", isOpen ? "d-flex" : "d-none", props.isSideBarExpanded ? "expanded" : "collapsed")}>
                        {renderList()}
                    </div>
                </>, document.body)}
        </div>
    )
}

const mapStateToProps = state => {
    return ({
        notifications: state.settings.notifications,
        user: state.user
    })
};

export default connect(mapStateToProps, {})(NotificationsPopup);