export const AdBuilderCreativeConstants = {

    SWIPE_CREATE_REQUEST: "SWIPE_CREATE_REQUEST",
    SWIPE_CREATE_REQUEST_LOADING: "SWIPE_CREATE_REQUEST_LOADING",
    SWIPE_CREATE_REQUEST_SUCCESS: "SWIPE_CREATE_REQUEST_SUCCESS",
    SWIPE_CREATE_REQUEST_ERROR: "SWIPE_CREATE_REQUEST_ERROR",

    SWIPE_FINALIZE_REQUEST: "SWIPE_FINALIZE_REQUEST",
    SWIPE_FINALIZE_REQUEST_SUCCESS: "SWIPE_FINALIZE_REQUEST_SUCCESS",
    SWIPE_FINALIZE_REQUEST_ERROR: "SWIPE_FINALIZE_REQUEST_ERROR",

    ADVERTISEMENT_CURRENT_PAGE: "ADVERTISEMENT_CURRENT_PAGE",
    ADVERTISEMENT_LIMIT_PER_PAGE: "ADVERTISEMENT_LIMIT_PER_PAGE",
    ADVERTISEMENT_SEARCH_VALUE: "ADVERTISEMENT_SEARCH_VALUE",
    ADVERTISEMENT_FILTER_STATUS: "ADVERTISEMENT_FILTER_STATUS",
    ADVERTISEMENT_TOTAL_COUNT: "ADVERTISEMENT_TOTAL_COUNT",
    GET_ALL_ADVERTISEMENT: "GET_ALL_ADVERTISEMENT",
    GET_ALL_ADVERTISEMENT_ERROR: "GET_ALL_ADVERTISEMENT_ERROR",
    ADVERTISEMENT_LOADING_STATUS: "ADVERTISEMENT_LOADING_STATUS",
    EMPTY_DSP: "EMPTY_DSP",
    GET_ADS_BY_BRIEF_ID: "GET_ADS_BY_BRIEF_ID",
    GET_ADS_BY_BRIEF_ID_ERROR: "GET_ADS_BY_BRIEF_ID_ERROR",


    MY_ADVERTISEMENT_CURRENT_PAGE: "MY_ADVERTISEMENT_CURRENT_PAGE",
    MY_ADVERTISEMENT_LIMIT_PER_PAGE: "MY_ADVERTISEMENT_LIMIT_PER_PAGE",
    MY_ADVERTISEMENT_SEARCH_VALUE: "MY_ADVERTISEMENT_SEARCH_VALUE",
    MY_ADVERTISEMENT_FILTER_STATUS: "MY_ADVERTISEMENT_FILTER_STATUS",
    MY_ADVERTISEMENT_TOTAL_COUNT: "MY_ADVERTISEMENT_TOTAL_COUNT",
    MY_GET_ALL_ADVERTISEMENT: "MY_GET_ALL_ADVERTISEMENT",
    MY_GET_ALL_ADVERTISEMENT_ERROR: "MY_GET_ALL_ADVERTISEMENT_ERROR",
    MY_ADVERTISEMENT_LOADING_STATUS: "MY_ADVERTISEMENT_LOADING_STATUS",
    
    VENDOR_ADVERTISEMENT_CURRENT_PAGE: "VENDOR_ADVERTISEMENT_CURRENT_PAGE",
    VENDOR_ADVERTISEMENT_LIMIT_PER_PAGE: "VENDOR_ADVERTISEMENT_LIMIT_PER_PAGE",
    VENDOR_ADVERTISEMENT_SEARCH_VALUE: "VENDOR_ADVERTISEMENT_SEARCH_VALUE",
    VENDOR_ADVERTISEMENT_TOTAL_COUNT: "VENDOR_ADVERTISEMENT_TOTAL_COUNT",
    VENDOR_GET_ALL_ADVERTISEMENT: "VENDOR_GET_ALL_ADVERTISEMENT",
    VENDOR_GET_ALL_ADVERTISEMENT_ERROR: "VENDOR_GET_ALL_ADVERTISEMENT_ERROR",
    VENDOR_ADVERTISEMENT_LOADING_STATUS: "VENDOR_ADVERTISEMENT_LOADING_STATUS",

    GET_ADVERTISEMENT_BY_ID: "GET_ADVERTISEMENT_BY_ID",
    GET_ADVERTISEMENT_BY_ID_ERROR: "GET_ADVERTISEMENT_BY_ID_ERROR",

    DSP_INFO_LOADING_STATUS: "DSP_INFO_LOADING_STATUS",
    ADD_DSP_DETAILS: "ADD_DSP_DETAILS",
    ADD_DSP_DETAILS_ERROR: "ADD_DSP_DETAILS_ERROR",

    GET_DSP_INFO_BY_ID: "GET_DSP_INFO_BY_ID",
    GET_DSP_INFO_BY_ID_ERROR: "GET_DSP_INFO_BY_ID_ERROR",
    REMOVE_DSP_INFO: "REMOVE_DSP_INFO",

    AD_STATUS_UPDATE: "AD_STATUS_UPDATE",
    AD_STATUS_UPDATE_ERROR: "AD_STATUS_UPDATE_ERROR",

    SEND_EMAIL_PREVIEW_LINK: "SEND_EMAIL_PREVIEW_LINK",
    SEND_EMAIL_PREVIEW_LINK_ERROR: "SEND_EMAIL_PREVIEW_LINK_ERROR",


    BB_AD_APPROVE_SUCCESS: "BB_AD_APPROVE_SUCCESS",
    BB_AD_APPROVE_ERROR: "BB_AD_APPROVE_ERROR",

    BB_AD_REVIEW_SUCCESS: "BB_AD_REVIEW_SUCCESS",
    BB_AD_REVIEW_ERROR: "BB_AD_REVIEW_ERROR",

    BB_UNMOUNT: 'BB_UNMOUNT',
    BB_UNMOUNT_ADS: 'BB_UNMOUNT_ADS',

    NEXD_CREATE_REQUEST_LOADING: 'NEXD_CREATE_REQUEST_LOADING',
    NEXD_CREATE_REQUEST_ERROR: 'NEXD_CREATE_REQUEST_ERROR',
    NEXD_CREATE_REQUEST_SUCCESS: 'NEXD_CREATE_REQUEST_SUCCESS',
    NEXD_EDIT_REQUEST_SUCCESS: 'NEXD_EDIT_REQUEST_SUCCESS',
    NEXD_EDIT_REQUEST_ERROR: 'NEXD_EDIT_REQUEST_ERROR',

    NEXD_PUBLISH_LOADING: "NEXD_PUBLISH_LOADING",
    NEXD_BUILDER_PUBLISH_LOADING: 'NEXD_BUILDER_PUBLISH_LOADING',
    NEXD_PUBLISH_SUCCESS: 'NEXD_PUBLISH_SUCCESS',
    NEXD_PUBLISH_ERROR: 'NEXD_PUBLISH_ERROR',
    NEXD_BUILDER_PUBLISH_SUCCESS: "NEXD_BUILDER_PUBLISH_SUCCESS",
    NEXD_BUILDER_PUBLISH_ERROR: "NEXD_BUILDER_PUBLISH_ERROR",

    AD_STATUS_DELETE: 'AD_STATUS_DELETE',
    AD_STATUS_DELETE_ERROR: 'AD_STATUS_DELETE_ERROR',
    DELETE_LOADING_STATUS: 'DELETE_LOADING_STATUS',

    AD_STATUS_ARCHIVE_INIT: 'AD_STATUS_ARCHIVE_INIT',
    AD_STATUS_ARCHIVE_SUCCESS: 'AD_STATUS_ARCHIVE_SUCCESS',
    AD_STATUS_ARCHIVE_ERROR: 'AD_STATUS_ARCHIVE_ERROR',

    BB_CREATIVE_LOADING: 'BB_CREATIVE_LOADING',
    BB_ADD_CREATIVE_SUCCESS: 'BB_ADD_CREATIVE_SUCCESS',
    BB_ADD_CREATIVE_ERROR: 'BB_ADD_CREATIVE_ERROR',

    AD_INFO_LOADING_STATUS: 'AD_INFO_LOADING_STATUS',
    GET_AD_INFO_BY_ID: 'GET_AD_INFO_BY_ID',
    GET_AD_INFO_BY_ID_ERROR: 'GET_AD_INFO_BY_ID_ERROR',

    AD_ASSETS_LOADING_STATUS: 'AD_ASSETS_LOADING_STATUS',
    GET_AD_ASSETS_BY_ID: 'GET_AD_ASSETS_BY_ID',
    GET_AD_ASSETS_BY_ID_ERROR: 'GET_AD_ASSETS_BY_ID_ERROR',

    AD_STATUS_CLONE: 'AD_STATUS_CLONE',
    AD_STATUS_CLONE_ERROR: 'AD_STATUS_CLONE_ERROR',
    CLONE_LOADING_STATUS: 'CLONE_LOADING_STATUS',

    AD_STATUS_RENAME: 'AD_STATUS_RENAME',
    AD_STATUS_RENAME_ERROR: 'AD_STATUS_RENAME_ERROR',
    RENAME_LOADING_STATUS: 'RENAME_LOADING_STATUS',

    AD_STATUS_START_STOP: "AD_STATUS_START_STOP",
    AD_STATUS_START_STOP_ERROR: "AD_STATUS_START_STOP_ERROR",
    START_STOP_LOADING_STATUS: "START_STOP_LOADING_STATUS",

    BB_DOWNLOAD_INIT: 'BB_DOWNLOAD_INIT',
    BB_DOWNLOAD_SUCCESS: 'BB_DOWNLOAD_SUCCESS',
    BB_DOWNLOAD_ERROR: 'BB_DOWNLOAD_ERROR',
    EMPTY_AD_ASSETS_DATA: 'EMPTY_AD_ASSETS_DATA',

    NEXD_REPORT_LOADING: 'NEXD_REPORT_LOADING',
    GET_AD_NEXD_REPORT_SUCCESS: 'GET_AD_NEXD_REPORT_SUCCESS',
    GET_AD_NEXD_REPORT_ERROR: 'GET_AD_NEXD_REPORT_ERROR',
    UNMOUNT_AD_NEXD_REPORT: 'UNMOUNT_AD_NEXD_REPORT',

    WIDESPACE_REPORT_LOADING: 'WIDESPACE_REPORT_LOADING',
    GET_AD_WIDESPACE_REPORT_SUCCESS: 'GET_AD_WIDESPACE_REPORT_SUCCESS',
    GET_AD_WIDESPACE_REPORT_ERROR: 'GET_AD_WIDESPACE_REPORT_ERROR',
    UNMOUNT_AD_WIDESPACE_REPORT: 'UNMOUNT_AD_WIDESPACE_REPORT',

    AIRTORY_REPORT_LOADING: 'AIRTORY_REPORT_LOADING',
    GET_AD_AIRTORY_REPORT_SUCCESS: 'GET_AD_AIRTORY_REPORT_SUCCESS',
    GET_AD_AIRTORY_REPORT_ERROR: 'GET_AD_AIRTORY_REPORT_ERROR',
    UNMOUNT_AD_AIRTORY_REPORT: 'UNMOUNT_AD_AIRTORY_REPORT',

    PRIMO_REPORT_LOADING: 'PRIMO_REPORT_LOADING',
    GET_AD_PRIMO_REPORT_SUCCESS: 'GET_AD_PRIMO_REPORT_SUCCESS',
    GET_AD_PRIMO_REPORT_ERROR: 'GET_AD_PRIMO_REPORT_ERROR',
    UNMOUNT_AD_PRIMO_REPORT: 'UNMOUNT_AD_PRIMO_REPORT',

    AD_ARCHIVE_TOGGLE_INIT: 'AD_ARCHIVE_TOGGLE_INIT',
    AD_ARCHIVE_TOGGLE_SUCCESS: 'AD_ARCHIVE_TOGGLE_SUCCESS',
    AD_ARCHIVE_TOGGLE_ERROR: 'AD_ARCHIVE_TOGGLE_ERROR',

    IMPRESSION_CHECK_LOADING:'IMPRESSION_CHECK_LOADING',
    IMPRESSION_CHECK_SUCCESS:'IMPRESSION_CHECK_SUCCESS',
    IMPRESSION_CHECK_ERROR:'IMPRESSION_CHECK_ERROR',

    GET_AD_CAMPAIGN_BY_ID:'GET_AD_CAMPAIGN_BY_ID',
    GET_AD_CAMPAIGN_BY_ID_ERROR:'GET_AD_CAMPAIGN_BY_ID_ERROR',
    EMPTY_AD_CAMPAIGN_DATA:'EMPTY_AD_CAMPAIGN_DATA',
    AD_CAMPAIGN_LOADING_STATUS:'AD_CAMPAIGN_LOADING_STATUS',

    
    DEAL_REPORT_LOADING: 'DEAL_REPORT_LOADING',
    GET_AD_DEAL_REPORT_SUCCESS: 'GET_AD_DEAL_REPORT_SUCCESS',
    GET_AD_DEAL_REPORT_ERROR: 'GET_AD_DEAL_REPORT_ERROR',
    UNMOUNT_AD_DEAL_REPORT: 'UNMOUNT_AD_DEAL_REPORT',
    
    ADDONS_REPORT_LOADING: 'ADDONS_REPORT_LOADING',
    GET_ADDONS_REPORT_SUCCESS: 'GET_ADDONS_REPORT_SUCCESS',
    GET_ADDONS_REPORT_ERROR: 'GET_ADDONS_REPORT_ERROR',
    
    CREATIVE_ADDONS_REPORT_LOADING: 'CREATIVE_ADDONS_REPORT_LOADING',
    GET_CREATIVE_ADDONS_REPORT_SUCCESS: 'GET_CREATIVE_ADDONS_REPORT_SUCCESS',
    GET_CREATIVE_ADDONS_REPORT_ERROR: 'GET_CREATIVE_ADDONS_REPORT_ERROR',

    DEAL_DOWNLOAD_REPORT_LOADING:"DEAL_DOWNLOAD_REPORT_LOADING",
    DEAL_DOWNLOAD_CREATIVE_REPORT_LOADING:"DEAL_DOWNLOAD_CREATIVE_REPORT_LOADING",
    CO2_DOWNLOAD_REPORT_LOADING:"CO2_DOWNLOAD_REPORT_LOADING",
    CO2_DOWNLOAD_CREATIVE_REPORT_LOADING:"CO2_DOWNLOAD_CREATIVE_REPORT_LOADING",
    DOWNLOAD_EMISSION_OPTIMISATION_REPORT_LOADING:"DOWNLOAD_EMISSION_OPTIMISATION_REPORT_LOADING",

    GET_AD_DEAL_REPORT_OPTIMISATION_SUCCESS:'GET_AD_DEAL_REPORT_OPTIMISATION_SUCCESS',
    GET_AD_DEAL_REPORT_OPTIMISATION_ERROR:'GET_AD_DEAL_REPORT_OPTIMISATION_ERROR',
    GET_AD_DEAL_REPORT_OPTIMISATION_LOADING:'GET_AD_DEAL_REPORT_OPTIMISATION_LOADING',

    GET_EMISSION_SUCCESS:'GET_EMISSION_SUCCESS',
    GET_EMISSION_ERROR:'GET_EMISSION_ERROR',
    GET_EMISSION_LOADING:'GET_EMISSION_LOADING',
    
    GET_EMISSION_SUMMARY_SUCCESS:'GET_EMISSION_SUMMARY_SUCCESS',
    GET_EMISSION_SUMMARY_ERROR:'GET_EMISSION_SUMMARY_ERROR',
    GET_EMISSION_SUMMARY_LOADING:'GET_EMISSION_SUMMARY_LOADING'

};
