import React from 'react'
import Skeleton from "../../../../helpers/Skeleton"
import Tooltip from '../../../utilities/Tooltip'
import classnames from "classnames"

function ReportCard(props) {
    const { header = "", tipDescription = "", objects = [], icon = "fas fa-eye", loading = false, isImage = false, className="", hasSvg= false, opt = false, subVal = false, value = false } = props;


    const renderValues = (object, i) => {
        return (
            <div className={`d-flex flex-wrap summary-card-line-${i}`}>
                {(i === 1 || i === 2) && <hr size="1" width="100%" color="lightgrey" />}
                <span className={classnames("summary-card-value", isImage ? "font_17" : "")}>{object.value}</span>
                <span className="summary-card-label w-100">{object.label}</span>
            </div>
        )
    }
    return (
        <>
            {loading ? <Skeleton className="summary-skeleton m-2" width="180px" height="240px" /> :
                <div className={classnames("d-flex flex-column flex-wrap  summary-card ", className)}>
                    {
                        <>
                            <div className="d-flex justify-content-between">
                                <h3 className="header-font bb-body-txt d-flex col-12 justify-content-between p-0">{header}
                                    {tipDescription ? <Tooltip default_id={tipDescription} arrowColor="#4DADC5" tooltipClassName="bb-tooltip-light" iconClassName="bb-tooltip-icon-light" light={true} className="m-0 cursor-pointer" label={""} iconname="fa-info">
                                        <div className="text-left bb-body-txt"><span>{tipDescription}</span></div>
                                    </Tooltip> : null}
                                </h3>
                            </div>
                            {opt ? <>
                                {value && subVal ? renderValues({"value" : value, "label": subVal}, 0) :  
                                props.children
                                }
                            </> : <> 
                            {objects && objects[0] && renderValues(objects[0], 0)}
                            {objects && objects[1] && renderValues(objects[1], 1)}
                            {objects && objects[2] && renderValues(objects[2], 2)}
                            {hasSvg ? <img src = {hasSvg}  className="summary-icon" alt="My Happy SVG"/> : <i className={`summary-icon ${icon}`} aria-hidden="true"></i>}
                            
                            </> }
                          
                        </>}
                </div>}
        </>
    )
}

export default ReportCard